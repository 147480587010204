import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import DeveloperHeroSection from "../../components/Developer/HeroSection";
import DeveloperFormSection from "../../components/Developer/FormComponent";
import React, { useEffect } from "react";
import {
  BlankSection,
  BlankSectionWithoutWidth,
} from "../../components/layouts/sections";

const Developer = ({ setIsHome }) => {
  useEffect(() => {
    setIsHome(true);
    return () => {
      setIsHome(false);
    };
  }, []);

  return (
    <>
      <BlankSection ngClass={"bg-hero-section bg-position"}>
        <Navbar />
        <DeveloperHeroSection />
      </BlankSection>

      <BlankSectionWithoutWidth ngClass={"bg-footer  bg-no-repeat "}>
        <DeveloperFormSection />
        <Footer />
      </BlankSectionWithoutWidth>
    </>
  );
};

export default Developer;
