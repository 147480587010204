import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import HeroSection from "../../components/HeroSection";
import MissionSection from "../../components/missionsection";
import React, { useEffect } from "react";
import {
  BlankSection,
  SectionWithHeading,
} from "../../components/layouts/sections";
import FaqSection from "../../components/faqsection";
import Scale from "../../components/scale";
import EcoSystem from "../../components/ecosystem";
import UseCase from "../../components/usecase";
import SolutionSection from "../../components/solutionsection";

const Home = ({ setIsHome }) => {
  useEffect(() => {
    setIsHome(true);
    return () => {
      setIsHome(false);
    };
  }, []);

  return (
    <>
      <BlankSection ngClass={"bg-hero-section"}>
        <Navbar />
        <HeroSection />
      </BlankSection>

      <BlankSection ngClass={"bg-no-repeat px-4"}>
        <EcoSystem />
      </BlankSection>

      <BlankSection ngClass={"bg-no-repeat"}>
        <SolutionSection />
      </BlankSection>

      <BlankSection ngClass={"bg-use-case bg-no-repeat px-4"}>
        <UseCase />
        <MissionSection />
      </BlankSection>

      <SectionWithHeading
        heading={`let's scale`}
        secondheading={`tombchain together`}
        ngClass={"font-Amarante text-white glow bg-footer"}
      >
        <Scale />
        <FaqSection />
        <Footer />
      </SectionWithHeading>
    </>
  );
};

export default Home;
