import React from "react";
import logo from "../../assets/img/logo.svg";
import tomb from "../../assets/img/tomb.svg";
import TwitterLogo from "../../assets/img/twitter-logo.svg";
import DiscordLogo from "../../assets/img/discord-logo.svg";
import TelegramLogo from "../../assets/img/telegram-logo.svg";
import FantomLogo from "../../assets/img/fantom.svg"


const navigation = {
  social: [
    {
      name: "Twitter",
      href: "https://twitter.com/tombchain",
      icon: TwitterLogo,
    },
    {
      name: "Discord",
      href: "https://discord.com/invite/vANnESmVdz",
      icon: DiscordLogo,
    },
    {
      name: "Telegram",
      href: "https://t.me/gameoftomb",
      icon: TelegramLogo,
    },
  ], //Tomb On Fantom
  columnOne: [
    { name: "Swap", href: "http://lif3.com/swap" },
    { name: "Bridge ", href: "http://lif3.com/bridge" },
    { name: "Farming", href: "http://lif3.com/farms" },
    { name: "Staking", href: "http://lif3.com/staking" },
    { name: "Block Explorer", href: "https://tombscout.com/" },
  ],
  columnTwo: [
    //fantom
    { name: "$ TOMB", href: "https://www.coingecko.com/en/coins/tomb" },
    { name: "$ TSHARE", href: "https://www.coingecko.com/en/coins/tomb-shares" },
    { name: "$ LIF3", href: "https://www.coingecko.com/en/coins/lif3" },
    { name: "$ LSHARE", href: "https://www.coingecko.com/en/coins/lif3-lshare" },
    { name: "$ L3USD", href: "https://www.coingecko.com/en/coins/l3usd" },
  ],
  columnThree: [
    //tools
    { name: "Tomb", href: "https://tomb.com/" },
    { name: "Tomb Swap", href: "https://swap.tomb.com/" },
    { name: "Tomb Info", href: "https://info.swap.tomb.com/" },
    { name: "Tomb Bridge (Coming Soon)", href: "https://tomb.com/bridge" },
  ],
  columnFour: [
    { name: "Fantom Foundation", href: "https://fantom.foundation/" },
    { name: "Fantom Wallet", href: "https://pwawallet.fantom.network/" },
    { name: "Fantom Scan", href: "https://ftmscan.com/" },
    { name: "Coin", href: "https://www.coingecko.com/en/coins/fantom/" },
    { name: "Stake", href: "https://fantom.foundation/ftm-staking/" },
  ],
  bottom: [
    { name: "Privacy policy", href: "#" },
    { name: "Terms & Service", href: "#" },
    { name: "Cookie policy", href: "#" },
  ],
};

export default function Footer() {
  return (
    <footer
      className=" font-Poppins"
      aria-labelledby="footer-heading"
      id="footer-section"
    >
      <div className="mx-auto py-12  sm:px-6 lg:py-16 lg:px-8 w-[90%]">
        <div className="sm:hidden mb-20 border-t border-b border-[#fdf1d461] p-5">
          <div className="flex space-x-10 justify-center">
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="font-medium text-gray-500 hover:text-gray-900"
                target="_blank"
              >
                <img
                  src={item.icon}
                  alt=""
                  className="h-8 w-8 mr-4"
                  aria-hidden="true"
                />
              </a>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-3 gap-8">
          <div className="md:grid md:grid-cols-1 col-span-1 md:gap-8 hidden sm:block">
            <div>
              <h3 className="text-sm font-semibold text-white tracking-wider uppercase">
                Social
              </h3>
              <ul className="mt-4 space-y-4">
                {navigation.social.map((item, index) => (
                  <li key={index}>
                    <a
                      href={item.href}
                      className="text-base text-light-yellow hover:text-yellow-tomb"
                      target="_blank"
                    >
                      <img
                        src={item.icon}
                        alt=""
                        className="h-6 w-6 inline mr-4"
                        aria-hidden="true"
                      />{" "}
                      <span className="inline"> {item.name}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 col-span-2 gap-8">
            <div>
              <h3 className="text-sm font-semibold text-white tracking-wider uppercase">
                Ecosystem
              </h3>
              <ul className="mt-4 space-y-4">
                {navigation.columnOne.map((item, index) => (
                  <li key={index}>
                    <a
                      href={item.href}
                      className="text-base text-light-yellow hover:text-yellow-tomb"
                      target="_blank"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <h3 className="text-sm font-semibold text-white tracking-wider uppercase">
              CoinGecko
              </h3>
              <ul className="mt-4 space-y-4">
                {navigation.columnTwo.map((item, index) => (
                  <li key={index}>
                    <a
                      href={item.href}
                      className="text-base text-light-yellow hover:text-yellow-tomb"
                      target="_blank"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <h3 className="text-sm font-semibold text-white tracking-wider uppercase mt-8 sm:mt-0">
                Tomb on Fantom
              </h3>
              <ul className="mt-4 space-y-4">
                {navigation.columnThree.map((item, index) => (
                  <li key={index}>
                    <a
                      href={item.href}
                      className="text-base text-light-yellow hover:text-yellow-tomb"
                      target="_blank"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <h3 className="text-sm font-semibold text-white tracking-wider uppercase mt-8 sm:mt-0 flex items-center">
                <img src={FantomLogo} height={20} width={20} className="mr-1"/>Fantom
              </h3>
              <ul className="mt-4 space-y-4">
                {navigation.columnFour.map((item, index) => (
                  <li key={index}>
                    <a
                      href={item.href}
                      className="text-base text-light-yellow hover:text-yellow-tomb"
                      target="_blank"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="mt-8 border-t border-yellow-tomb pt-8 flex md:items-center md:justify-between">
          <p className="sm:mt-8 text-base text-white md:mt-0 md:order-1 flex justify-center items-center w-20 sm:w-auto">
            <img className="h-16 w-auto sm:h-10 inline" src={logo} alt="" />
            <span className="inline ml-24 hidden sm:block">
              Copyright &copy; Tomb 2022
            </span>
          </p>

          <div className="sm:flex sm:space-x-16 md:order-2 ml-auto mr-10">
            {navigation.bottom.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="text-light-yellow hover:text-light-yellow block sm:mt-0 mt-3"
                target="_blank"
              >
                <span>{item.name}</span>
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
}
