import React from "react";

const sections = [
  {
    title: "VISION",
    desc: "Our vision is utilizing the safety and security of the Fantom Network and take that one step further with cheaper fees, a faster network and the security of a permissioned layer 2.",
  },
  {
    title: "MISSION",
    desc: "Our mission is to stop at nothing in order to build the safest blockchain in the world where Developers are free to build with the latest tools and network security and users have the peace of mind to use a blockchain free from scams.",
  },
  {
    title: "BUSINESS",
    desc: "We pride ourselves in helping new dApps every step of the way.  From testing to securing to everything in-between.  Our vast team has experience in all levels of coding and security.",
  },
];

export default function MissionSection() {
  return (
    <>
      <div className="sm:grid sm:grid-cols-3 mb-20 gap-20 mt-[140px]">
        <div className="font-Amarante text-5xl sm:col-span-1 uppercase leading-[60px] mb-10 sm:mb-0 text-center sm:text-left">
          <h2>WHAT MAKES US THE FIRST CHOICE?</h2>
        </div>
        <div className="sm:col-span-2">
          {sections.map((section, index) => (
            <div
              key={index}
              className="relative rounded-lg px-10 py-14 shadow-sm sm:space-x-3 bg-faint-purple font-Poppins mb-6 sm:grid sm:grid-cols-5 background-cloud-left mission-section-box"
            >
              <div className="text-4xl sm:text-3xl font-Amarante sm:col-span-1 sm:mt-0 mt-24 mb-4 text-center">{section.title}</div>
              <div className="font-Poppings sm:pl-20 col-span-4">
                <p className="text-sm text-[#ffffffcc] text-center sm:text-left ">{section.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
