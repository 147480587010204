import React from "react";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { A11y, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import FantomLogo from "../../assets/img/fantom.svg"

import "swiper/css";

const HeroSection = () => {
  const sections = [
    {
      title: "Ecosystem",
      desc: "Your new home for Decentralized Applications",
      target: "https://tombchain.com/ecosystem",
    },
    {
      title: "Permissioned Blockchain",
      desc: "means safety for all users. Learn More",
      target: "#faq-section",
    },
    {
      title: "DEVELOPERS",
      desc: "Easy EVM compatibility, Developer Support, trusted tools",
      target: "https://tombchain.com/developers",
    },
    {
      title: "BRIDGE",
      desc: "From Fantom to Tomb Chain and back",
      target: "https://lif3.com/bridge",
    },
  ];
  return (
    <div>
      <div className="text-5xl md:text-7xl tracking-tight font-Amarante text-yellow-tomb-light text-center pt-12 pb-10  md:pt-12 md:pb-10 lg:py-16 xl:py-20">
        <p>A Fast and Secure</p>
        <p className="flex">Layer 2 Optimistic Rollup running on </p>
        <p className="flex items-center justify-center mt-1"><img src={FantomLogo} className="md:h-[65px] h-[40px] mr-1 " /> Fantom</p>
      </div>

      <div className="mt-3 sm:hidden">
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          spaceBetween={30}
          slidesPerView={1.3}
          navigation
          pagination={{ clickable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          {sections.map((section, index) => (
            <SwiperSlide key={index} className={`h-full`}>
              <div
                key={index}
                className="relative rounded-lg  bg-offWhite  px-6 py-5 flex items-center space-x-3 font-Poppins h-full"
              >
                <div className="flex-1 min-w-0 ">
                  <a href={section.target} className="focus:outline-none">
                    <p className="text-md font-medium font-Poppins text-white align-text-left">
                      {section.title}
                    </p>
                    <p className="text-sm font-Poppins text-light-yellow align-text-left">
                      {section.desc}
                    </p>
                  </a>
                </div>
                <div className="bg-lightPurple px-2 py-2 rounded-md">
                  <ChevronRightIcon
                    className="h-4 w-4 text-yellow-tomb items-center"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="grid-cols-1 gap-4 sm:grid-cols-4 mt-5 hidden sm:grid min-h-[129px]">
        {sections.map((section, index) => (
          <a href={section.target}>
            <div
              key={index}
              className="relative rounded-lg  bg-offWhite  px-6 py-5 flex items-center space-x-3 font-Poppins h-full"
            >
              <div className="flex-1 min-w-0 ">
                <p className="text-md font-medium font-Poppins text-white align-text-left">
                  {section.title}
                </p>
                <p className="text-sm font-Poppins text-[#ffffffcc] align-text-left">
                  {section.desc}
                </p>
              </div>
              <div className="bg-lightPurple px-2 py-2 rounded-md">
                <ChevronRightIcon
                  className="h-4 w-4 text-yellow-tomb items-center"
                  aria-hidden="true"
                />
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default HeroSection;
