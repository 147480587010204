import React, { useState } from 'react';
import Home from './views/Home';
import Developer from './views/Developer';
import Ecosystem from './views/Ecosystem';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import './global.css'
import Layouts from './components/layouts';

function App() {

  const [isHome, setIsHome] = useState(false);

  return (
    <Router>
      {/* <div className="relative overflow-hidden">
        <div className={`background`} />
        {isHome && <div className={`background-home`} />} */}
      <div className={`background bg-stars`}/>
      <Layouts>
        <Routes>
          <Route path="/" element={<Home setIsHome={setIsHome} />} />
          <Route path="/developers" element={<Developer setIsHome={setIsHome} />} />
          <Route path="/ecosystem" element={<Ecosystem setIsHome={setIsHome} />} />
        </Routes>
      </Layouts>
      {/* </div> */}
    </Router>

  );
}

export default App;
