import React from "react";

import "swiper/css";

const HeroSection = () => {

  React.useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML  = `(function() {
      try{
      var f = document.createElement("iframe");
      f.src = 'https://forms.tombchain.com/quantumftg/form/TombChainDeveloperAccess/formperma/de-JCZFdyLubJd0bvK6OrRckUsSZTuMpk8fOz4LKLBw?zf_rszfm=1';
      f.style.border="none";
      f.style.height="1000px";
      f.style.width="100%";
      f.style.transition="all 0.5s ease";
      var d = document.getElementById("zf_div_de-JCZFdyLubJd0bvK6OrRckUsSZTuMpk8fOz4LKLBw");
      d.appendChild(f);
      window.addEventListener('message', function (){
      var evntData = event.data;
      if( evntData && evntData.constructor == String ){
      var zf_ifrm_data = evntData.split("|");
      if ( zf_ifrm_data.length == 2 ) {
      var zf_perma = zf_ifrm_data[0];
      var zf_ifrm_ht_nw = ( parseInt(zf_ifrm_data[1], 10) + 15 ) + "px";
      var iframe = document.getElementById("zf_div_de-JCZFdyLubJd0bvK6OrRckUsSZTuMpk8fOz4LKLBw").getElementsByTagName("iframe")[0];
      if ( (iframe.src).indexOf('formperma') > 0 && (iframe.src).indexOf(zf_perma) > 0 ) {
      var prevIframeHeight = iframe.style.height;
      if ( prevIframeHeight != zf_ifrm_ht_nw ) {
      iframe.style.height = zf_ifrm_ht_nw;
      }
      }
      }
      }
      }, false);
      }catch(e){}
      })();`;
    document.body.appendChild(script);
  }, []);
  return (
    <div className="mb-48 mt-24 max-w-7xl m-auto">
      <div id="zf_div_de-JCZFdyLubJd0bvK6OrRckUsSZTuMpk8fOz4LKLBw"></div>
    </div>
  );
};

export default HeroSection;
