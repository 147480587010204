import {
  BadgeCheckIcon,
  CashIcon,
  ClockIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import InclinedRightArrow from '../../assets/img/incline-right-arrow.svg'

const actions = [
  {
    title: "Permissioned - Private - Safe",
    description:
      "Build and expand alongside other reputable developers. To make Tomb Chain a safe environment for both developers and consumers, everyone who builds on it has been Whitelisted.",
    href: "#",
    icon: ClockIcon,
    iconForeground: "bg-white",
    iconBackground: "bg-[#9685FF]",
  },
  {
    title: "Developers",
    description:
      "Everything you need to know to get started is available right here. We make it as simple as possible to start constructing from scratch or port code from another chain.",
    href: "https://tombchain.com/developers",
    icon: BadgeCheckIcon,
    iconForeground: "bg-white",
    iconBackground: "bg-[#9685FF]",
  },
  {
    title: "EVM Compatability",
    description:
      "EVM is the blockchain developer industry standard, and Tomb Chain is an Optimistic Rollup that can easily support EVM-compliant dApps.",
    href: "#",
    icon: UsersIcon,
    iconForeground: "bg-white",
    iconBackground: "bg-[#9685FF]",
  },
  {
    title: "Bridging",
    description:
      "Bridging is a necessary step in getting started using Tomb Chain. To begin with Tomb Chain bridging, connect to Tomb Bridge via the Lif3 protocol.",
    href: "https://lif3.com/bridge",
    icon: CashIcon,
    iconForeground: "bg-white",
    iconBackground: "bg-[#9685FF]",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Scale() {
  return (
    <div className="rounded-lg  overflow-hidden shadow divide-y  sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px font-Poppins  mx-auto max-w-7xl px-4">
      {actions.map((action, index) => (
          <div
            key={index}
            className={classNames(
              "relative group px-6 py-10 bg-[#7c4ebd4d] focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 sm:flex"
            )}
          >
            <div className="mb-8 sm:mb-0">
              <span
                className={classNames(
                  action.iconBackground,
                  action.iconForeground,
                  "rounded-[50%] inline-flex p-3"
                )}
              >
                <action.icon className="h-6 w-6" aria-hidden="true" />
              </span>
            </div>
            <div className="sm:ml-10 relative">
              <h3 className="text-xl font-medium">
                <a href={action.href} className="focus:outline-none text-[#FDF1D4]">
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  {action.title}
                </a>
              </h3>
              <p className="mt-2 text-sm text-[#ffffffcc]">{action.description}</p>

              <span
              className="pointer-events-none absolute top-0 right-0 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
             <img src = {InclinedRightArrow}  className="h-4 w-4 sm:h-10 " alt =""/> 
            </span>
            </div>
          </div>
      ))}
    </div>
  );
}
