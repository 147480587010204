import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import logo from "../../assets/img/logo.svg";
import tomb from "../../assets/img/tomb.svg";
import TwitterLogo from "../../assets/img/twitter-logo.svg";
import DiscordLogo from "../../assets/img/discord-logo.svg";
import TelegramLogo from "../../assets/img/telegram-logo.svg";

const navigation = [
  {
    id: 1,
    name: "Home",
    href: "/",
  },
  {
    id: 2,
    name: "Ecosystem",
    href: "/ecosystem",
  },
  {
    id: 3,
    name: "Community",
    href: "#footer-section",
  },
  {
    id: 4,
    name: "Developers",
    href: "/developers",
  },
];

const section = [
  {
    id: 1,
    name: "Home",
    href: "/",
  },
  {
    id: 2,
    name: "Ecosystem",
    href: "/ecosystem",
  },
  {
    id: 3,
    name: "Community",
    href: "/#footer-section",
  },
  {
    id: 4,
    name: "Developers",
    href: "/developers",
  },
];

const miniSection = [
  {
    name: "Twitter",
    href: "https://twitter.com/tombchain",
    icon: TwitterLogo,
  },
  {
    name: "Discord",
    href: "https://discord.com/invite/vANnESmVdz",
    icon: DiscordLogo,
  },
  {
    name: "Telegram",
    href: "https://t.me/gameoftomb",
    icon: TelegramLogo,
  },
];

export default function Navbar() {
  return (
    <div className="relative pt-6 sm:pb-6 pb-2 sticky-navbar">
      <Popover>
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <nav
            className="relative flex items-center justify-between sm:h-10 md:justify-center"
            aria-label="Global"
          >
            <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0 ">
              <div className="flex items-center justify-between w-full md:w-auto">
                <a href="/#" className="flex">
                  <span className="sr-only">Workflow</span>
                  <img className="h-8 w-auto sm:h-10" src={logo} alt="" />
                </a>
                <div className="-mr-2 flex items-center md:hidden">
                  <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className="h-6 w-6 text-yellow-tomb" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="hidden md:flex md:space-x-10">
              {navigation.map((item, index) => (
                <a
                  key={index}
                  href={item.href}
                  className="font-medium text-yellow-tomb glow"
                >
                  {item.name}
                </a>
              ))}
            </div>
            <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
              <span className="rounded-xl bg-btnGradient">
                <a
                  href="https://lif3.com/bridge"
                  className="inline-flex items-center px-4 py-2 bg-dark-purple rounded-xl text-base font-medium rounded-md text-white"
                >
                  Get started
                </a>
              </span>
            </div>
          </nav>
        </div>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-10 top-0 inset-x-0 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg bg-mobileMenu ring-1 ring-black ring-opacity-5 overflow-hidden h-[100vh]">
              <div className="px-4 pt-6 flex items-center justify-between">
                <div>
                  <img className="h-8 w-auto" src={logo} alt="" />
                </div>
                <div>
                  <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-white hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-8 pb-3 bg-mobileMenu">
                <div className="pb-5">
                  {section.map((item, index) => (
                    <a
                      key={index}
                      href={item.href}
                      className="block py-4 text-center text-base text-3xl font-Amarante glow"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="pb-14">
                  {miniSection.map((item, index) => (
                    <a
                      key={index}
                      href={item.href}
                      className="block py-3 text-center text-xs font-Poppins glow"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
}
