import React from "react";
import Footer from "../footer";
import Navbar from "../navbar";

const HeaderFooter = ({ children }) => {
  return (
    <>
      <div className="">
        {children}
      </div>
    </>
  );
}

export default HeaderFooter;