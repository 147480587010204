import { useState } from "react";
import CircleArrow from "../../../assets/img/circle-arrow.svg";
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline';

const action = [
  {
    projectName: "Lif3",
    projectDescription: "The premium Dex, Farm and Bridge on Tomb Chain",
    projectURL: "https://lif3.com",
    projectURI: "https://swap.tomb.com/images/tokens/LIF3.svg",
    projectCategory: "DeFi, Bridge, DEX, Portfolio",
    ProjectURLName: "Lif3",
  },
  {
    projectName: "Tomb Scout",
    projectDescription: "Tomb Scout is Tomb Chain's native block explorer",
    projectURL: "https://tombscout.com",
    projectURI: "https://swap.tomb.com/images/tokens/TOMB.svg",
    projectCategory: "DeFi",
    ProjectURLName: "Tomb Scout",
  },
  {
    projectName: "Tomb Scout",
    projectDescription: "Tomb Scout is Tomb Chain's native block explorer",
    projectURL: "https://tombscout.com",
    projectURI: "https://swap.tomb.com/images/tokens/TOMB.svg",
    projectCategory: "DeFi, NFT",
    ProjectURLName: "Tomb Scout",
  },
  // {
  //   projectName: "Lif3",
  //   projectDescription: "Lorem ipsum dolor",
  //   projectURL: "https://lif3.com",
  //   projectURI: "https://swap.tomb.com/images/tokens/LIF3.svg",
  //   projectCategory: "DeFi",
  // },
  // {
  //   projectName: "Lif3",
  //   projectDescription: "Lorem ipsum dolor",
  //   projectURL: "https://lif3.com",
  //   projectURI: "https://swap.tomb.com/images/tokens/LIF3.svg",
  //   projectCategory: "Tool",
  // },
  // {
  //   projectName: "Lif3",
  //   projectDescription: "Lorem ipsum dolor",
  //   projectURL: "https://lif3.com",
  //   projectURI: "https://swap.tomb.com/images/tokens/LIF3.svg",
  //   projectCategory: "NFT",
  // },
  // {
  //   projectName: "Portfolio",
  //   projectDescription: "Lorem ipsum dolor",
  //   projectURL: "https://lif3.com",
  //   projectURI: "https://swap.tomb.com/images/tokens/LIF3.svg",
  //   projectCategory: "Portfolio",
  // },
];
const tag = [
  {
    tagName: "All dApps",
    tagValue: "All",
  },
  {
    tagName: "DeFi",
    tagValue: "DeFi",
  },
  {
    tagName: "NFT",
    tagValue: "NFT",
  },
  {
    tagName: "Bridge",
    tagValue: "Bridge",
  },
  {
    tagName: "On-Ramp",
    tagValue: "onRamp",
  },
  {
    tagName: "Tool",
    tagValue: "tool",
  },
  {
    tagName: "Portfolio",
    tagValue: "portfolio",
  },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function EcoSystem() {
  const [filterData, setFilterData] = useState(action);
  const [dropdown, setDropdown] = useState(false);
  const [tagValue, setTagValue] = useState('All');
  const filterTags = (value) => {
    if (value == "All") {
      setFilterData(action);
      setTagValue(value)
      setDropdown(false);
      return;
    }
    setTagValue(value)
    const testData = action.filter((data) =>
      data.projectCategory.includes(value)
    );
    setFilterData(testData);
    setDropdown(false);
  };
  return (
    <div className="max-w-7xl m-auto mb-48 min-h-[500px]">
      <div className="flex gap-3 mb-8 hidden md:flex">
        {tag &&
          tag.map((tag, index) => (
            <div
              className="rounded-md bg-gradient-to-r p-[1px] from-indigo-500 via-purple-500 to-pink-500 cursor-pointer"
              key={index}
              onClick={() => filterTags(tag.tagValue)}
            >
              <div className="px-7 py-2 bg-dark-purple rounded-md">
                <p>{tag.tagName}</p>
              </div>
            </div>
          ))}
      </div>
      <div className="md:hidden">
        <div className="relative">
          <button
            onClick={() => setDropdown(!dropdown)} // setDropdown(!dropdown)
            className={`uppercase py-4  btn mb-2 font-semibold font-Poppins shadow1 textShadow1 flex justify-between px-6 items-center gradient-border w-full rounded-xl`}
          >
            {tagValue}
            {dropdown ? (
                <ChevronUpIcon style={{ width: '16px', marginLeft: '5px' }} />
              ) : (
                <ChevronDownIcon style={{ width: '16px', marginLeft: '5px' }} />
              )}
          </button>
          {dropdown && (
            <>
              <div className="absolute  w-full bg-dark-purple btn z-50 gradient-border rounded-xl py-4">
                {tag.map((tag, index) => (
                  <div
                    className="cursor-pointer hover:bg-[#0e0320]"
                    key={index}
                    onClick={() => filterTags(tag.tagValue)}
                  >
                    <div className="px-7 py-2 ">
                      <p>{tag.tagName}</p>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="sm:grid sm:grid-cols-3 gap-10">
        {filterData &&
          filterData.map((action, index) => (
            <div
              className="text-center mb-10 sm:mb-0 rounded-xl gradient-border"
              key={index}
            >
              <div className="rounded-xl py-4">
                <div className="">
                  <span className={classNames("rounded-[50%] inline-flex p-3")}>
                    <img src={action.projectURI} className="h-16 w-12" />
                  </span>
                </div>
                <div className="">
                  <h3 className="text-lg font-medium">{action.projectName}</h3>
                  <p className="mt-2 text-sm text-[#ffffffcc] text-light-yellow">
                    {action.projectDescription}
                  </p>
                  <a
                    href={`${action.projectURL}`}
                    className="mt-5 text-sm text-tomb-purple flex justify-center"
                  >
                    {action.ProjectURLName}
                    <img src={CircleArrow} className="ml-3" />
                  </a>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
