import { ClockIcon } from "@heroicons/react/outline";
import CircleArrow from '../../assets/img/circle-arrow.svg'

const action = [
  {
    title: "TRUSTED",
    description:
      "Why permissioned? We maintain our stance on a permissioned blockchain to protect our users and keep out bad actors.  No more Scams, no more rug pulls, no more honeypots, etc",
    icon: ClockIcon,
    iconForeground: "bg-white",
    iconBackground: "bg-[#9685FF]",
  },
  {
    title: "FAST",
    description:
      "Tomb Chain was already built to be extremely fast.  Now couple that with being an L2 on already one of the fastest L1s in the world... Lightning Fast!",
    icon: ClockIcon,
    iconForeground: "bg-white",
    iconBackground: "bg-[#9685FF]",
  },
  {
    title: "INEXPENSIVE",
    description:
      "The Fantom L1 is already recognized as one of the most inexpensive L1s avaialble.  Our use of Gas is limited to transactional data back to the L1.  Our gas is Super Cheap and easy to access in a number of different ways.",
    icon: ClockIcon,
    iconForeground: "bg-white",
    iconBackground: "bg-tomb-purple",
  },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function EcoSystem() {
  return (
    <>
      <div className="sm:grid sm:grid-cols-3 gap-20 py-16  sm:py-12 lg:py-16 xl:py-20">
        {action && action.map((action, index) => (
          <div className="text-center mb-10 sm:mb-0" key={index}>
            <div className="mb-5">
              <span
                className={classNames(
                  action.iconBackground,
                  action.iconForeground,
                  "rounded-[50%] inline-flex p-3"
                )}
              >
                <action.icon className="h-6 w-6" aria-hidden="true" />
              </span>
            </div>
            <div className="">
              <h3 className="text-lg font-medium">
                {action.title}
              </h3>
              <p className="mt-2 text-sm text-[#ffffffcc] text-light-yellow">{action.description}</p>
              <p className="mt-5 text-sm text-tomb-purple flex justify-center">Join the ecosystem <img src={CircleArrow} className="ml-3" /></p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
