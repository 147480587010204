import React from "react";

import "swiper/css";

const HeroSection = () => {
  return (
    <div className="py-24 px-4 sm:py-12  lg:py-24 mb-8">
      <div className="text-7xl tracking-tight font-Amarante text-yellow-tomb-light text-center">
        <p>Tomb Chain Ecosystem</p>
      </div>
      <p className="max-w-md mx-auto text-sm font-Poppins text-yellow-tomb-light mt-8 text-center leading-6">
        In the near future, anyone, anwhere, can thrive in the decentralizated
        economy witn Tomb Chain
      </p>
      <div className="flex justify-center mt-10 font-xs font-Poppins sm:mb-20 mb-[130px]">
        <div>
          <a
            href="/#"
            className="rounded-lg items-center justify-center text-base sm:px-7 sm:py-3 px-4 py-3 text-white bg-btnGradient "
          >
            Get started
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
