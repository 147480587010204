import { ChevronDownIcon } from "@heroicons/react/outline";
import React, { useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Faq() {
  const [disclosures, setDisclosures] = useState([
    {
      id: "1",
      isOpen: false,
      buttonText: "How do I get started?",
      panelText:
        "Open Metamask, tap on Settings > Add Network. Then enter the following details: </br></br> Network Name: Tomb Chain </br></br> RPC URL: <a href=\"https://rpc.tombchain.com/\" target=\"_blank\" class=\"faq-link\">https://rpc.tombchain.com/</a> </br></br> Chain ID: 6969 </br></br> Currency Symbol: TOMB </br></br> Block Explorer URL (Optional):  <a href=\"https://tombscout.com/\" target=\"_blank\" class=\"faq-link\">https://tombscout.com/</a>"
    },
    {
      id: "2",
      isOpen: false,
      buttonText: "What is a Layer 2?",
      panelText:
        "Layer 2 refers to a secondary framework or protocol that is built on top of an existing blockchain system. In other words, the layer 2 application is built on top of an existing Blockchain. The Tomb Chain submits many transactions in 1 block on Fantom, which is how it achieves its lightning speed!"
    },
    // {
    //   id: "3",
    //   isOpen: false,
    //   buttonText: "What is a Rollup?",
    //   panelText:
    //     "Expedita maxime laboriosam ut enim rotione recusandae sapiente perspicitatis quo. Vero molestiae at modi doloribus numquam. Dolore est est corrupti quia. Veritatis maxime eius perspiciatis molestias aut labore consequatur et similique. Et rerum possimus minima sequi veritatis fugiat ab voluptatibus."
    // },
    // {
    //   id: "4",
    //   isOpen: false,
    //   buttonText: "What does EVM-Compatible mean?",
    //   panelText:
    //     "Expedita maxime laboriosam ut enim rotione recusandae sapiente perspicitatis quo. Vero molestiae at modi doloribus numquam. Dolore est est corrupti quia. Veritatis maxime eius perspiciatis molestias aut labore consequatur et similique. Et rerum possimus minima sequi veritatis fugiat ab voluptatibus."
    // },
    {
      id: "5",
      isOpen: false,
      buttonText: `What does "Permissioned" mean?`,
      panelText:
        "Tomb Chain works on an invite only basis, meaning that Developers need to KYC and apply to build on Tomb Chain. This creates accountability with the dApps that are deployed on Tomb Chain, and ensures we keep the network safe from bad actors, whilst maintaining a fast Layer 2!"
    },
    {
      id: "6",
      isOpen: false,
      buttonText: `How can I bridge to Tomb Chain?`,
      panelText:
        "1. Go to <a href=\"https://lif3.com/bridge\" target=\"_blank\" class=\"faq-link\">https://lif3.com/bridge</a>. <br/><br/>2. Select the asset you would like to bridge over. <br/><br/> 3. Select Bridge <br/><br/>4. Done"
    },
    // {
    //   id: "7",
    //   isOpen: false,
    //   buttonText: `Where can I monitor  the on-chain data?`,
    //   panelText:
    //     "Expedita maxime laboriosam ut enim rotione recusandae sapiente perspicitatis quo. Vero molestiae at modi doloribus numquam. Dolore est est corrupti quia. Veritatis maxime eius perspiciatis molestias aut labore consequatur et similique. Et rerum possimus minima sequi veritatis fugiat ab voluptatibus."
    // },
    // {
    //   id: "8",
    //   isOpen: false,
    //   buttonText: `Can I run a node?`,
    //   panelText:
    //     "Expedita maxime laboriosam ut enim rotione recusandae sapiente perspicitatis quo. Vero molestiae at modi doloribus numquam. Dolore est est corrupti quia. Veritatis maxime eius perspiciatis molestias aut labore consequatur et similique. Et rerum possimus minima sequi veritatis fugiat ab voluptatibus."
    // },
    // {
    //   id: "9",
    //   isOpen: false,
    //   buttonText: `What happens if Fantoms Gas price spikes?`,
    //   panelText:
    //     "Expedita maxime laboriosam ut enim rotione recusandae sapiente perspicitatis quo. Vero molestiae at modi doloribus numquam. Dolore est est corrupti quia. Veritatis maxime eius perspiciatis molestias aut labore consequatur et similique. Et rerum possimus minima sequi veritatis fugiat ab voluptatibus."
    // },
    // {
    //   id: "10",
    //   isOpen: false,
    //   buttonText: `Is there a withdrawal delay from L2 to L1?`,
    //   panelText:
    //     "Expedita maxime laboriosam ut enim rotione recusandae sapiente perspicitatis quo. Vero molestiae at modi doloribus numquam. Dolore est est corrupti quia. Veritatis maxime eius perspiciatis molestias aut labore consequatur et similique. Et rerum possimus minima sequi veritatis fugiat ab voluptatibus."
    // }
  ]);

  const handleClick = (id) => {
    setDisclosures(
      disclosures.map((d) =>
        d.id === id ? { ...d, isOpen: !d.isOpen } : { ...d, isOpen: false }
      )
    );
  };

  return (
    <>
      <div className="sm:grid sm:grid-cols-3 mb-20 gap-20 mt-[100px] mx-auto max-w-7xl px-4" id="faq-section">
        <div className="font-Amarante uppercase text-5xl col-span-1 text-center text-yellow-tomb-light leading-[60px] pt-5 mb-10 sm:mb-0 glow">
          <h2>Everything you wanted to ask</h2>
        </div>
        <div className="col-span-2 font-Poppins">
          <div className="mx-auto divide-y-2 divide-gray-200">
            <dl className="border-t border-[#fdf1d461]">
              {disclosures.map(({ id, isOpen, buttonText, panelText }) => (
                <div key={id} className="pt-6 pb-6 border-b border-[#fdf1d461]">
                  <dt className="text-lg text-[#FDF1D4]">
                    <button
                      className="text-left w-full flex justify-between items-start text-gray-400"
                      onClick={() => handleClick(id)}
                      aria-expanded={isOpen}
                      {...(isOpen && { "aria-controls": id })}
                    >
                      <span className="font-medium text-white">
                        {buttonText}
                      </span>
                      <span className="ml-6 h-7 flex items-center">
                        <ChevronDownIcon
                          className={classNames(
                            isOpen ? "-rotate-180" : "rotate-0",
                            "h-6 w-6 transform"
                          )}
                          aria-hidden="true"
                        />
                      </span>
                    </button>
                  </dt>

                  {isOpen && (
                    <dd className="mt-2 pr-12">
                      <div className="text-base text-[#ffffffcc]" dangerouslySetInnerHTML={ { __html: panelText } }></div>
                    </dd>
                  )}
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}