import React from "react";

const BlankSection = ({ children, ngClass }) => {
  return (
    <section className={ngClass}>
      <div className={`mx-auto lg:px-8 max-w-7xl m-auto`}>{children}</div>
    </section>
  );
};
const BlankSectionWithoutWidth = ({ children, ngClass }) => {
  return (
    <section className={ngClass}>
      <div className={`px-4 mx-auto lg:px-8 `}>{children}</div>
    </section>
  );
};

const SectionWithHeading = ({ heading, secondheading, ngClass, children }) => {
  return (
    <section className={`mx-auto ${ngClass}`}>
      <div className="text-center mb-12 lg:mb-24">
        <h1 className="font-normal text-5xl sm:text-6xl lg:text-6xl tracking-tight relative font-Amarante uppercase m-auto leading-[60px] sm:leading-[75px]">
          {heading}
        </h1>
        <h1 className="font-normal text-5xl sm:text-6xl lg:text-6xl tracking-tight relative font-Amarante uppercase m-auto leading-[60px] sm:leading-[75px]">
          {secondheading}
        </h1>
      </div>
      {children}
    </section>
  );
};

const SectionWithHeadingDesc = ({
  topHeading,
  heading,
  description,
  ngClass,
  children,
}) => {
  return (
    <BlankSection ngClass={ngClass}>
      <div className="sm:px-4 w-full text-center">
        <h1 className="text-[2rem] md:text-header font-sourcesansB font-bold tracking-wide relative">
          <span className="text-secondary-500 after:content-[''] after:absolute after:w-24 md:after:w-32 after:h-0 md:after:h-2 after:bottom-[-20px] md:after:bottom-[-20px] after:left-[38%] md:after:left-[45%] after:border-2 md:after:border-4 after:border-secondary-500">
            <span className="text-black mr-4">{topHeading}</span>
            <span className="text-secondary-500 mr-2">{heading}</span>
          </span>
        </h1>
        <p className="text-blue opacity-80 py-16 px-10 md:px-[10rem]">
          {description}
        </p>
      </div>
      <div>{children}</div>
    </BlankSection>
  );
};

const SectionWithTopBottomHeadingDesc = ({
  topHeading,
  heading,
  description,
  ngClass,
  children,
}) => {
  return (
    <BlankSection ngClass={ngClass}>
      <div className="sm:px-4 w-full text-center">
        <h1 className="text-[2rem] md:text-header font-sourcesansB font-bold tracking-wide relative">
          <span className="block text-primary-500">{topHeading}</span>{" "}
          <span className="block text-secondary-500 after:content-[''] after:absolute after:w-24 md:after:w-32 after:h-0 md:after:h-2 after:top-24 md:after:top-36 after:left-[35%] md:after:left-[45%] after:border-2 md:after:border-4 after:border-secondary-500">
            {heading}
          </span>
        </h1>
        <p className="text-blue opacity-80 py-16 px-10 md:px-[10rem]">
          {description}
        </p>
      </div>
      <div>{children}</div>
    </BlankSection>
  );
};

const BlankSectionNoYSpacing = ({ children, ngClass }) => {
  return (
    <section className={ngClass}>
      <div className="px-4 mx-auto max-w-7xl lg:px-8">{children}</div>
    </section>
  );
};

const BlankSectionNoXSpacing = ({ children, ngClass }) => {
  return (
    <section className={ngClass}>
      <div className="py-8 xl:py-16">{children}</div>
    </section>
  );
};

const BlankSectionNoSpacing = ({ children, ngClass }) => {
  return (
    <section className={ngClass}>
      <div>{children}</div>
    </section>
  );
};

export {
  BlankSection,
  SectionWithHeading,
  SectionWithHeadingDesc,
  BlankSectionNoYSpacing,
  SectionWithTopBottomHeadingDesc,
  BlankSectionNoXSpacing,
  BlankSectionNoSpacing,
  BlankSectionWithoutWidth
};
