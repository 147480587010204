import React from "react";

export default function SolutionSection() {
  return (
    <div className="py-16  sm:py-12 lg:py-16 xl:py-20">
      <div className="sm:grid sm:grid-cols-2 gap-20 rounded-lg px-10 py-10 shadow-sm bg-solution ">
        <div className="col-span-1 leading-[60px] text-yellow-tomb-light">
          <p className= "font-Poppins text-sm pb-5 pl-4">Tomb Chain is solving Fantom's</p>
          <p className = "font-Amarante sm:text-5xl text-4xl uppercase pb-[70px] pl-4">SECURITY. SPEED. SIMPLICITY. SAVINGS. SCALABILITY. STORAGE.</p>
        </div>
        <div className="col-span-1">
          <div className="sm:grid sm:grid-cols-1 h-64">
              {/* background image */}
          </div>
        </div>
      </div>
    </div>
  );
}
