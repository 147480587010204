import Navbar from '../../components/navbar'
import Footer from "../../components/footer";
import HeroSection from '../../components/ecosystem/HeroSection'
import EcoSection from '../../components/ecosystem/EcoSection'
import React, { useEffect } from 'react';
import { BlankSection, BlankSectionWithoutWidth } from '../../components/layouts/sections';

const Home = ({ setIsHome }) => {

  useEffect(() => {
    setIsHome(true);
    return () => {
      setIsHome(false);
    };
  }, []);

  return (
    <>
      <BlankSection ngClass={'bg-hero-section bg-position'}>
        <Navbar />
        <HeroSection />
      </BlankSection>

      <BlankSectionWithoutWidth ngClass={'bg-no-repeat bg-footer '}>
        <EcoSection />
        <Footer />
      </BlankSectionWithoutWidth>

    </>

  );
};

export default Home;
