import { BadgeCheckIcon, ClockIcon } from "@heroicons/react/outline";

const rowData = [
  {
    title: "Save on Gas Fees",
    description:
      "You will always pay Gas Fees when transacting on the Blockchain, regardless of which chain you choose. Tomb Chain features some of the most affordable gas fees of any chain.",
    href: "#",
    icon: ClockIcon,
    iconForeground: "bg-white",
    iconBackground: "bg-[#9685FF]",
  },
  {
    title: "Lightning Fast Transactions",
    description:
      "The Fantom L1 Blockchain handles transaction validation, allowing Tomb Chain to process transactions at near-instant speeds.",
    href: "#",
    icon: BadgeCheckIcon,
    iconForeground: "bg-white",
    iconBackground: "bg-[#9685FF]",
  },
  {
    title: "Find your use cases",
    subtitle: " "
  },
  {
    title: "Safest Blockchain in DeFi",
    description:
      "To deploy contracts on Tomb Chain, developers must be Whitelisted, which eliminates the nefarious bad actors that afflict every other chain.",
    href: "#",
    icon: ClockIcon,
    iconForeground: "bg-white",
    iconBackground: "bg-[#9685FF]",
  },
  {
    title: "Permissioned Blockchain",
    description:
      "Tomb Chain works on an invite only basis, meaning that Developers need to KYC and apply to build on Tomb Chain. This creates accountability with the dApps that are deployed on Tomb Chain, and ensures we keep the network safe from bad actors, whilst maintaining a fast Layer 2!",
    href: "#",
    icon: BadgeCheckIcon,
    iconForeground: "bg-white",
    iconBackground: "bg-[#9685FF]",
  },
  {
    title: "Run on Fantom",
    description:
      "Fantom's validator nodes constitute a worldwide, trustless, and leaderless Proof-of-Stake network, and Fantom secures every transaction on Tomb Chain.",
    href: "#",
    icon: BadgeCheckIcon,
    iconForeground: "bg-white",
    iconBackground: "bg-[#9685FF]",
  },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function UseCase() {
  return (
    <>
      <div className="sm:grid sm:grid-cols-3 gap-20">
        <div className="sm:hidden text-center mb-10">
          <p className="mb-5"> </p>
          <p className="font-normal text-5xl sm:text-6xl lg:text-6xl tracking-tight relative font-Amarante uppercase m-auto leading-[60px] sm:leading-[75px] glow">Find your use cases</p>
        </div>
        {rowData && rowData.map((action, index) => (
          <div key={index}>
            {
              action.subtitle ? (
                <div className="sm:block hidden">
                  <p className="mb-5">{action.subtitle}</p>
                  <p className="font-normal text-5xl sm:text-6xl lg:text-6xl tracking-tight relative font-Amarante uppercase m-auto leading-[60px] sm:leading-[75px]">{action.title}</p>
                </div>
              ) : (
                <div className="mb-10 sm:mb-0">
                  <div className="mb-5 text-center sm:text-left">
                    <span
                      className={classNames(
                        action.iconBackground,
                        action.iconForeground,
                        "rounded-[50%] inline-flex p-3"
                      )}
                    >
                      <action.icon className="h-6 w-6" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="text-center sm:text-left">
                    <h3 className="text-lg font-medium">
                      {action.title}
                    </h3>
                    <p className="mt-2 text-sm text-[#ffffffcc]">{action.description}</p>
                  </div>
                </div>
              )
            }
          </div>
        ))}
      </div>
    </>
  );
}
